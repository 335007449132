import 'foundation-sites/dist/css/foundation.min.css';
import './App.scss';
// import companySettings from './config/settings';
// import resourceData from './config/resources';
import menu from './config/main_nav'
import React, { useState, useEffect } from 'react';
// import ReactGA from 'react-ga';
import axios from 'axios';
import Quest from './components/quest_page';
import SfPlusPage from './components/sfplus_page/sfplus_page';
import RewardsPage from './components/rewards_page/rewards_page';
import CommunityPage from './components/community_page/community_page';
import TeamPage from './components/team_page/team_page';
import NextStepsPage from './components/nextsteps_page/nextsteps_page';
import Loading         from './components/loading/loading';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

// Component to handle route changes and refresh data
function RouteChangeHandler({ fetchCompanyData }) {
  const location = useLocation();
  
  useEffect(() => {
    fetchCompanyData();
  }, [location.pathname, fetchCompanyData]);
  
  return null;
}

export default function App() {
  // const [height, setHeight] = useState(window.innerHeight);
  const [settings, setSettings] = useState({});
  const [currentSlideList, setCurrentSlideList] = useState({});
  // const [resData, setResData] = useState(resourceData);
  const [loading, setLoading] = useState(true)
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [consentOpen, setConsentOpen] = useState(true);
  const [passwordEntered, setPasswordEntered] = useState(false);



  function setCompanyData(data) {
    if (data) {
      setSettings(data);
      if (data.topics && data.topics.length > 0) {
      setCurrentSlideList(
        {
          'askPassword': 0,
          'hero': 1,
          'topics':2 ,
          'chests': 3,
          'trails': 4 ,
          'congrats': 5
        }
      ); 
      } else if (data.trailmixes && data.trailmixes.length > 0) {
        setCurrentSlideList(
          {
            'askPassword': 0,
            'hero': 1,
            'chests': 2,
            'trails': 3 ,
            'congrats': 4
          }
        ); 
      } else {
        setCurrentSlideList(
          {
            'askPassword': 0,
            'hero': 1,
            'congrats': 2
          }
        ); 
      }
      setLoading(false);
    }
  };

  const fetchCompanyData = async () => {
    let companyName = 'default';
    let host = window.location.host.split('.')[0];
    if (host === 'localhost:3000' || host === 'www' || host === 'trailblazerchallenge') {
    } else {
      companyName = host;
    }
    let fetch_url = 'https://trailblazer-cms.herokuapp.com/accounts/' + companyName + '.json'
    
    await axios(fetch_url
    ).then((response) => {
      setCompanyData(response.data);
      console.log('Fresh data fetched');
    }).catch((e) => {
      console.log(e);
    });
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  if (loading === true) {
    return <Loading/>
  } else {
    return (
    <Router>
      <div id="app" className="app">
        <RouteChangeHandler fetchCompanyData={fetchCompanyData} />
        <Switch>
          <Route
            exact path={menu.trails.link}
            render={(props) =>
              <Quest {...props}
                consentOpen={consentOpen}
                setConsentOpen={setConsentOpen}
                settings={settings}
                passwordEntered={passwordEntered}
                setPasswordEntered={setPasswordEntered}
                setSettings={setSettings}
                currentSlideList={currentSlideList}
              />
            }
          />
          <Route
            path={menu.rewards.link}
            render={(props) =>
              <RewardsPage {...props}
                consentOpen={consentOpen}
                setConsentOpen={setConsentOpen}
                settings={settings}
                formModalOpen={formModalOpen}
                setFormModalOpen={setFormModalOpen}
              />
            }
          />
          <Route
            path={menu.sfplus.link}
            render={(props) =>
              <SfPlusPage {...props}
                consentOpen={consentOpen}
                setConsentOpen={setConsentOpen}
                settings={settings}
              />
            }
          />

          <Route
            path={menu.connect.link}
            render={(props) =>
              <CommunityPage {...props}
              consentOpen={consentOpen}
              setConsentOpen={setConsentOpen}
                settings={settings}
              />
            }
          />
          <Route
            path={menu.team.link}
            render={(props) =>
              <TeamPage {...props}
                consentOpen={consentOpen}
                setConsentOpen={setConsentOpen}
                settings={settings}
              />
            }
          />
          <Route
            path={menu.nextsteps.link}
            render={(props) =>
              <NextStepsPage {...props}
                consentOpen={consentOpen}
                setConsentOpen={setConsentOpen}
                settings={settings}
              />
            }
          />
        </Switch>
      </div>
    </Router>
  );
}
}